<template>
  <div class="reveal-overlay">
    <div class="mobile-menu">
      <div class="nav">
        <div class="nav__content">
          <div class="nav__logo">
            <router-link to="/">
              <img src="@/assets/images/logo.svg" alt="Distri-Bib">
            </router-link>
          </div>
          <ul class="menu-list nav__menu">
            <li>
              <router-link to="/">
                <font-awesome-icon icon="home" class="nav__icon"/>
                Home
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="box" class="nav__icon"/>
                Pakbonnen
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="store" class="nav__icon"/>
                Webshop
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="list-ul" class="nav__icon"/>
                Suggestielijsten
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="book" class="nav__icon"/>
                Boek.com
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="building" class="nav__icon"/>
                Distri-Bib.com
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                <font-awesome-icon icon="user-circle" class="nav__icon"/>
                Account
              </router-link>
            </li>
          </ul>
          <div class="nav__contact">
            <ul>
              <li>
                <a href="tel:0478 474 113" rel="nofollow" target="_blank">
                  <font-awesome-icon icon="phone" class="nav__icon"/>
                  0478 474 113
                </a>
              </li>
              <li>
                <a :href="prefix + 'shop'" rel="nofollow" target="_blank">
                  <font-awesome-icon icon="envelope" class="nav__icon"/>
                  info@distribib.be
                </a>
              </li>
              <li>
          <span>
            <font-awesome-icon icon="archive" class="nav__icon"/>
            BTW: BE 478 693 713
          </span>
              </li>
              <li>
                <a href="https://goo.gl/maps/CyGDza5MnN9gywYHA" target="_blank">
                  <font-awesome-icon icon="map-marked-alt" class="nav__icon mav__icon--maps"/>Distri-Bib bvba
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationModal',
  setup() {
    const prefix = process.env.VUE_APP_BASE_URL;

    return { prefix }
  }
};

</script>
<style lang='scss' scoped>
.reveal-overlay {
  display: none;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  background-color: rgba(0,0,0,.75);

  &.is-active {
    display: block;
  }

  .mobile-menu {
    top: 0;
    display: block;
    width: 33%;
    background: $white;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    height: 100%;
    min-height: 100%;
    margin-left: 0;
    border: 0;
    border-radius: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 2rem;
    margin-right: auto;
    overflow-y: auto;

    .nav {
      &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__logo {
        height: $navbar-height;
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }

      &__menu {
        li a {
          color: $dark-grey;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 150%;
          border-bottom: 1px solid $grey;
          padding: 1rem 0 1rem 0;

          &.router-link-active {
            color: $black;
          }
        }

        li:first-child a {
          padding-top: $global-padding*2;
        }

        li:last-child a {
          border-bottom: none;
        }
      }

      &__contact {
        margin: auto 0 1rem 0;

        li {
          padding: 0.3rem 0 0.3rem 1.5rem;

          a,
          span {
            color: $dark-grey;
            line-height: 150%;
          }

          a:hover {
            color: $black;
          }
        }
      }

      &__icon {
        margin-right: 1rem;

        &--maps {
          display: block;
          float: left;
        }
      }
    }
  }
}
</style>
