<template>
  <div class="content" id="book-detail">
    <div class="results-main">
      <BooksDetailTable v-if="!isBusy" :data="data" :details="bookDetails" />
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {getAPI} from "@/utils/useApi";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import { useStore } from 'vuex'
import LoadingBlock from "@/app/Components/LoadingBlock";
import BackButton from "@/app/Components/BackButton";
import BooksDetailTable from "@/app/Components/BooksDetailTable";

export default {
  name: 'BookDetail',
  components: {
    BooksDetailTable,
    BackButton,
    LoadingBlock,
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const data = ref([]);
    const bookDetails = ref([]);
    const route = useRoute();
    const store = useStore();
    const uuid = route.params.id;

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/books/' + uuid)
          .then((response) => {
            data.value = response.data

            if (store.getters.getSearchedBook.length === 0) {
              getAPI('/api/books/search/isbn/' + encodeURIComponent(data.value['isbn']))
                  .then((response) => {
                    if (response.data.length > 0) {
                      let result = response.data[0];
                      result['lines'].forEach(function (book) {
                        bookDetails.value = {
                          'barcode': book['barcode'],
                          'product_name': book['product_name'],
                          'packing_slip': result['name'],
                          'release_date': book['release_date'],
                          'uuid': book['book_uuid'],
                          'ordered': book['so_line_qty'],
                          'delivered': book['qty_to_deliver'],
                          'scanned': book['qty_confirmed'],
                          'returned': book['qty_retour'],
                          'status': result['state'],
                          'reservation_date': result['reservation_date'],
                          'customer_parcel': result['customer_parcel'],
                          'sight_sending': result['sight_sending'],
                          'packing_slip_uuid': result['uuid'],
                        }
                      })
                      store.commit('setSearchedBook', bookDetails);
                    }
                  });
            } else {
              bookDetails.value = store.getters.getSearchedBook;
            }

            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });

    return {
      data,
      bookDetails
    }
  }
};

</script>
<style lang="scss">
#book-detail {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>

