<template>
  <div class="tile box is-primary is-child">
    <p class="title">Zoek een besteld boek op</p>
    <p class="description">Hier kun je een boek en de bijbehorende gegevens terugvinden aan de hand van de titel of het ISBN.</p>
    <div class="field has-addons">
      <p class="control">
    <span class="select">
      <select v-model="input.searchType">
        <option value="isbn">ISBN</option>
        <option value="title">Titel</option>
      </select>
    </span>
      </p>
      <p class="control is-expanded">
        <input class="input" type="text" placeholder="Zoeken..." v-model="input.searchQuery" v-on:keyup.enter="search">
      </p>
      <p class="control">
        <button class="button is-primary" v-on:click="search" type="submit">
          <font-awesome-icon icon="search"/>
        </button>
      </p>
    </div>
  </div>
</template>

<script>

import {reactive} from "@vue/reactivity";
import {useRouter} from "vue-router";

export default {
  name: "SearchBooksBlock",
  setup() {
    const input = reactive({searchQuery: "", searchType: "title"});
    const router = useRouter()

    function search() {

      if (input.searchQuery && input.searchType) {
        router.push({name: 'books-search', query: {'type': input.searchType, 'id': encodeURIComponent(input.searchQuery)}});

      }
    }

    return {
      search,
      input,
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .description {
    margin-bottom: 33px;
  }

  .select::after {
    border-color: #A7A7A7;
  }
}
</style>
