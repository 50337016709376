<template>
  <div class="tile is-child featured" v-if="fetchResultsFromCache.length > 0">
    <div class="featured__top">
      <h3 class="title" >In de kijker</h3>
      <a :href="prefix + 'shop'" class="url" target="_blank">Bezoek onze webshop</a>
    </div>
    <div class="featured__content box">
      <Carousel :settings="settings" v-if="fetchResultsFromCache.length > 6">
        <slide v-for="book in fetchResultsFromCache" :key="book" v-on:click="goToWebshop(book.url)">
          <div class="item-image">
            <figure class="image" v-if="book.image">
              <img :src="book.image" :alt="book.name">
            </figure>
          </div>
          <p class="item-title">{{ book.name }}</p>
          <p class="item-author">{{ book.author }}</p>
        </slide>
        <template #addons>
          <navigation/>
        </template>
      </Carousel>
      <div class="feat" v-else>
        <div class="feat__item" v-for="book in fetchResultsFromCache" :key="book" v-on:click="goToWebshop(book.url)">
          <div class="item-image">
            <figure class="image" v-if="book.image">
              <img :src="book.image" :alt="book.name">
            </figure>
          </div>
          <p class="item-title">{{ book.name }}</p>
          <p class="item-author">{{ book.author }}</p>
        </div>
      </div>
    </div>
  </div>
  <LoadingBlock v-else-if="fetchResultsFromCache.length !== 0" />
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import LoadingBlock from "@/app/Components/LoadingBlock";

export default {
  name: "FeaturedBooksBlock",
  props: {
    featuredBooks: Array
  },
  components: {
    LoadingBlock,
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    fetchResultsFromCache() {
      return this.$store.getters.getFeaturedBooks;
    }
  },
  methods: {
    goToWebshop(url) {
      window.open(url, '_blank');
    },
  },
  setup() {
    const prefix = process.env.VUE_APP_BASE_URL;
    const settings = {
      itemsToShow: 4,
      snapAlign: 'end',
      mouseDrag: true,
      itemsToScroll: 4,
      modelValue: 4,
      wrapAround: true,
    }

    return { settings, prefix };
  },
}
</script>

<style lang="scss">

.featured {
  padding-bottom: 2.5rem;
  display: block;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;

    .title, .url {
      margin-bottom: 0 !important;
    }

    .url {
      font-size: .9rem;
    }
  }

  &__content {
    padding: .5rem 1rem;

    .carousel {
      position: relative;

      &__icon {
        fill: $dark-grey;
        transition: .3s all ease-in-out;

        &:hover {
          fill: $black;
        }
      }
      &__prev, &__next {
        background: none;
      }

      &__slide {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        figure {
          height: 100%;
          width: auto;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
          }
        }

        .item-image {
          height: 160px;
          overflow: hidden;
          margin-bottom: 1rem;
        }

        .item-title, .item-author {
          padding: 0 5px 0 5px;
          font-weight: 500;
          font-size: .9rem;
        }
        .item-author {
          color: $dark-grey;
        }
      }

      &__pagination {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -50px;

        &-item {
          display: flex;
          align-items: center;
        }

        &-button {
          width: 8px;
          height: 8px;
          background: #A7A7A7;
          border-radius: 50%;
          padding: 0;

          &--active {
            background: $primary;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }

  .feat {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__item {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    figure {
      height: 100%;
      width: auto;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
      }
    }

    .item-image {
      height: 160px;
      overflow: hidden;
      margin-bottom: 1rem;
    }

    .item-title, .item-author {
      padding: 0 5px 0 5px;
      font-weight: 500;
      font-size: .9rem;
    }
    .item-author {
      color: $dark-grey;
    }
  }
}

</style>
