<template>
  <table class="table is-hoverable is-fullwidth result-table">
    <thead>
    <tr>
      <th style="width: 50%">Klant - Perceel</th>
      <th>Pakbon</th>
      <th>Datum verzending</th>
    </tr>
    </thead>
    <tbody v-if="!resultsFromRoute()">
      <tr class="result-table__item"><td>Geen gegevens gevonden.</td></tr>
    </tbody>
    <template v-for="(result, key) in resultsFromRoute()" v-bind:key="key">
      <tbody class="result-table__group">
      <tr @click="openResultGroup(key)">
        <td :colspan="6">
          {{ key }}
          <font-awesome-icon icon="caret-up" v-if="resultGroupsStatus[key]"/>
          <font-awesome-icon icon="caret-down" v-else/>
        </td>
      </tr>
      </tbody>
      <tbody v-if="resultGroupsStatus[key]">
      <tr v-for="packingSlip in result" v-bind:key="packingSlip" class="result-table__item" @click="goToDetail(packingSlip['uuid'])">
        <td>{{ packingSlip['customer_parcel'] }}</td>
        <td>{{ packingSlip['name'] }}</td>
        <td>{{ packingSlip['reservation_date'] }}</td>
      </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
import {useRoute} from 'vue-router'

export default {
  name: "PackingSlipsResultTable",
  props: {
    data: Array
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  methods: {
    goToDetail(id) {
      const url = process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
    openResultGroup(key) {
      this.resultGroupsStatus[key] = !this.resultGroupsStatus[key];
    },
    resultsFromRoute() {
      if (this.data[0]) {
        const route = useRoute();
        const routeName = route.name;

        let results;

        // Sort function
        const groupBy = function (data, key) {
          return data.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };

        // Sort data
        results = this.data;

        // Split orders from sight sendings
        results = groupBy(results, 'sight_sending');
        results = (routeName.includes('pakbonnen')) ? results['false'] : results['true'];

        // Split into separate categories based on status
        results = groupBy(results, 'status');

        let type = routeName.split('-')[1];

        if (!type) {
          type = 'done';
        }

        let books = results;

        if (type === 'other') {
          delete results['done'];
          delete results['invoiced'];
          delete results['draft'];

          books = results;
        } else {
          books = results[type];
        }

        // Order results by customer parcels
        if (books && books.length > 0) {
          books = groupBy(books, 'customer_parcel');
        } else {
          books = null;
        }

        return books;
      }
      else {
        return false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  border-radius: 5px;

  &__group {
    font-size: 1rem;
    font-weight: 600;

    svg {
      margin-left: .5rem;
    }
  }

  td, th {
    border: solid #e5e5e5;
    border-width: 1px 0;
  }

  th {
    width: 33%;

    &:last-child {
      width: 100%;
    }
  }

  tbody tr {
    cursor: pointer;
  }

  tr:hover {
    a {
      color: $primary;

      path {
        fill: $primary;
      }
    }
  }

  td {
    padding: 1rem;
    font-size: .9rem;

    a {
      color: $black;
      text-decoration: underline;
      svg {
        transition: all .4s ease-in-out;
        margin-left: 1rem;
      }
      path {
        fill: $black;
      }
    }
  }

  th {
    border-top: none;
    padding: 1.5rem 1rem;
    font-weight: 500;
    color: #000000;
  }
}
</style>
