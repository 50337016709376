<template>
  <div class="login__wrapper">
    <div class="login__content">
      <div class="login__logo">
        <img src="@/assets/images/logo.svg" alt="Distri-Bib">
      </div>
      <Login />
    </div>
  </div>
</template>

<script>
import Login from "../Components/Login";

export default {
  name: 'Auth',
  components: {
    Login,
  }
}
</script>

<style lang="scss" scoped>

.login {
  &__wrapper {
    display: flex;
    height: 100%;
    min-height: 100vh;
    background: $darker-gray;
    justify-content: center;
  }

  &__content {
    margin-top: 5%;
    min-width: 80%;

    @include md {
      min-width: 25%;
    }
  }

  &__logo {
    text-align: center;
    margin-bottom: 1rem;
    img {
      margin: auto;
      min-width: 75%;
    }
  }
}
</style>
