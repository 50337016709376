<template>
  <aside class="column is-2 nav is-narrow-mobile">
    <div class="nav__content">
      <div class="nav__logo">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="Distri-Bib">
        </router-link>
      </div>
      <ul class="menu-list nav__menu">
        <li class="nav__menu--item">
          <router-link to="/">
            <font-awesome-icon icon="home" class="nav__icon"/>
            Home
          </router-link>
        </li>
        <li class="nav__menu--item">
          <a :href="prefix + 'shop'" target="_blank">
            <font-awesome-icon icon="store" class="nav__icon"/>
            Webshop
          </a>
        </li>
        <li class="nav__menu--item">
          <a :href="prefix + 'shop/category/suggestielijsten-tweewekelijks-10'" target="_blank">
            <font-awesome-icon icon="list-ul" class="nav__icon"/>
            Suggestielijsten
          </a>
        </li>
        <li class="nav__menu--item">
          <a href="http://www.boekenbank.be/" target="_blank">
            <font-awesome-icon icon="book" class="nav__icon"/>
            Boekenbank.be
          </a>
        </li>

        <li class="nav__menu--item nav__menu--contact nav__menu--contact--start">
          <span>
            <font-awesome-icon icon="info" class="nav__icon"/>
            Contacteer ons:
          </span>
        </li>

        <li class="nav__menu--item nav__menu--contact">
          <a href="mailto:info@distribib.be" rel="nofollow" target="_blank">
            <font-awesome-icon icon="envelope" class="nav__icon"/>
            info@distribib.be
          </a>
        </li>
        <li class="nav__menu--item nav__menu--contact">
          <span>
            <font-awesome-icon icon="phone" class="nav__icon"/>
            03/376 33 63
          </span>
        </li>
        <li class="nav__menu--item nav__menu--contact">
          <span class="is-flex">
            <font-awesome-icon icon="map-marked-alt" class="nav__icon"/>
            <span class="p-0">
            Neerveld 7, <br>
            2550 Kontich
            </span>
          </span>
        </li>
        <li class="nav__menu--item nav__menu--contact">
          <span>
            <font-awesome-icon icon="archive" class="nav__icon"/>
            BTW: BE 478 693 713
          </span>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>

import store from "../../../store";

export default {
  name: "Navigation",
  data() {
    return {
      isAuth: false,
    }
  },
  mounted() {
    this.isAuth = store.getters.isAuthenticated;
  },
  setup() {
    const prefix = process.env.VUE_APP_BASE_URL;

    return { prefix }
  }
}
</script>

<style lang="scss" scoped>

.nav {
  border-right: 1px solid $grey;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0;
  display: none;

  @include xl() {
    display: block;
    height: 100vh;
  }

  &__content {
    height: 100%;
    width: inherit;
    position: fixed;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    height: $navbar-height;
    border-bottom: 1px solid $grey;
    display: flex;
    align-items: center;
    padding-left: 1rem;

    a {
      min-width: 60%;
    }

    img {
      width: 100%;
    }
  }

  &__menu {
    &--item a, &--item span {
      display: block;
      color: $dark-grey;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      border-bottom: 1px solid $grey;
      padding: 1rem 0 1rem 1.5rem;
      position: relative;

      &.router-link-active {

        color: $black;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          height: 24px;
          width: 3px;
          border-radius: 0 5px 5px 0;
          background: $primary;
        }
      }
    }

    &--contact span, &--contact a{
      border-bottom: none;
      padding-top: 0;

      &:hover {
        background: none;
      }
    }

    &--contact--start {
      padding: 1rem 0 0 0;
      margin-bottom: 1rem;
      background: $primary;
      span {
        color: $white;
      }

      &:hover {
        background: $primary !important;
      }
    }

    &--item:first-child a {
      padding-top: $global-padding*2;
    }

    &--item:last-child a {
      border-bottom: none;
    }

    &--contact {
      &:hover {
        background: none;
        color: $dark-grey !important;
      }
    }
  }

  &__icon {
    margin-right: 1rem;
    min-width: 1rem;

    &--maps {
      display: block;
      float: left;
    }
  }
}

//&.router-link-exact-active {
//  color: #42b983;
//}
</style>
