<template>
<div>
  <button class="button is-loading is-fullwidth mb-4">Loading</button>
</div>
</template>

<script>
export default {
  name: "LoadingBlock"
}
</script>

<style lang="scss" scoped>
.button {
  border: none;
}
</style>
