<template>
  <table class="table is-hoverable is-fullwidth result-table">
    <thead>
    <tr>
      <th style="width: 10%">Factuurnummer</th>
      <th style="width: 20%">Klant</th>
      <th style="width: 20%">Leveringsadres</th>
      <th style="width: 10%">Factuurdatum</th>
      <th style="width: 10%">Vervaldatum</th>
      <th style="width: 20%">Bron documenten</th>
      <th style="width: 10%">Totaal</th>
    </tr>
    </thead>
    <tbody>
      <tr class="result-table__item" v-if="!resultsFromRoute()">
        <td colspan="7">Geen gegevens gevonden.</td>
      </tr>
      <tr v-for="invoice in resultsFromRoute()" v-bind:key="invoice" class="result-table__item" @click="goToDetail(invoice['uuid'])">
        <td>{{ invoice['invoice_number'] }}</td>
        <td>{{ invoice['client'] }}</td>
        <td>{{ invoice['delivery_address'] }}</td>
        <td>{{ invoice['invoice_date'] }}</td>
        <td>{{ invoice['due_date'] }}</td>
        <td>
          <template v-for="(packing_slip, index) in invoice['packing_slips']" v-bind:key="packing_slip">
            {{ packing_slip['name'] }}<span v-if="index !== invoice['packing_slips'].length - 1">, </span>
          </template>
        </td>
        <td> &euro;{{ invoice['total'] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {useRoute} from 'vue-router'

export default {
  name: "InvoicesResultTable",
  props: {
    data: Array
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: 'invoices-detail', params: { id: id }})
    },
    resultsFromRoute() {
      if (this.data[0]) {
        const route = useRoute();

        if (route.name === 'invoices-search') return this.data;

        // Sort function
        const groupBy = function (data, key) {
          return data.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };

        // Group data by invoice type
        let results = this.data;
        results = groupBy(results, 'invoice_type');

        // Show normal invoices or credit invoices based on route
        results = (route.name === 'invoices') ? results['invoice'] : results['credit_invoice'];

        return results;
      }
      else {
        return false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  border-radius: 5px;

  &__group {
    font-size: 1rem;
    font-weight: 600;

    svg {
      margin-left: .5rem;
    }
  }

  td, th {
    border: solid #e5e5e5;
    border-width: 1px 0;
  }

  th {
    width: 33%;

    &:last-child {
      width: 100%;
    }
  }

  tbody tr {
    cursor: pointer;
  }

  tr:hover {
    a {
      color: $primary;

      path {
        fill: $primary;
      }
    }
  }

  td {
    padding: 1rem;
    font-size: .9rem;

    a {
      color: $black;
      text-decoration: underline;
      svg {
        transition: all .4s ease-in-out;
        margin-left: 1rem;
      }
      path {
        fill: $black;
      }
    }
  }

  th {
    border-top: none;
    padding: 1.5rem 1rem;
    font-weight: 500;
    color: #000000;
  }
}
</style>
