<template>
  <div class="content" id="packing-slips">
    <div class="results-header">
      <div class="results-header__title">
        <h3 class="title">Alle pakbonnen</h3>
      </div>
      <PackingSlipsTabs/>
    </div>
    <div class="results-main">
      <PackingSlipsTypeTabs/>
      <PackingSlipsResultTable v-if="!isBusy" :data="data" />
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";
import PackingSlipsTabs from "@/app/Components/PackingSlipsTabs";
import PackingSlipsTypeTabs from "@/app/Components/PackingSlipsTypeTabs";
import PackingSlipsResultTable from "@/app/Components/PackingSlipsResultTable";

export default {
  name: 'PackingSlips',
  components: {
    PackingSlipsResultTable,
    PackingSlipsTypeTabs,
    PackingSlipsTabs,
    LoadingBlock,
    BackButton,
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/packing_slips')
          .then((response) => {
            data.value = response.data;
            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });

    return {
      data,
    }
  }
};

</script>
<style lang="scss">
#packing-slips {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
