<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <form novalidate @submit.prevent="onSubmit()">
          <p class="help is-danger" v-if="errors.authentication">{{ errors.authentication }}</p>
          <div class="field">
            <label class="label" for="username">E-mail</label>
            <div class="control">
              <input
                  v-bind:class="[{'is-danger': errors.username}, 'input']"
                  v-model="model.username"
                  @keyup="validateInput"
                  @blue="validateInput"
                  type="email"
                  id="username">
              <p class="help is-danger" v-if="errors.username">{{ errors.username }}</p>
            </div>
          </div>
          <div class="field">
            <label class="label" for="password">Wachtwoord</label>
            <div class="control">
              <input v-bind:class="[{'is-danger': errors.password}, 'input']" v-model="model.password" type="password" id="password">
              <p class="help is-danger" v-if="errors.password">{{ errors.password }}</p>
            </div>
          </div>
          <div class="field field--button">
            <div class="control">
              <button class="button is-primary" v-bind:class="{'is-loading': isBusy}">Aanmelden</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive } from '@vue/reactivity';
import {useStore} from "vuex";
import {postAPI} from "@/utils/useApi";
import {useRouter} from "vue-router";

export default {
  name: 'Login',
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const model = reactive({username: "", password: ""});
    const errors = reactive({username: "", password: "", authentication: ""});
    const store = useStore();
    const router = useRouter();

    function onSubmit() {
      errors.username = '';
      errors.password = '';

      if (model.username && model.password) {
        // Login call
        store.commit('setBusy');

        // if (process.env.NODE_ENV) {
        //   console.debug('USE MOCK DATA');
        //   store.dispatch('login', {"jwt_token":"b'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjE5NCwiZXhwIjoxNjc1NjExMzcxLCJpYXQiOjE2NzUwMDY1NzF9.cvIfJFuQ5PbTiHn3x1jquXqtdAM-nEBjIiGkWOGDMFg'","name":"Testpersoon","language":"nl_BE","email":"info@distribib.be"});
        //   store.commit('clearBusy');
        //   router.push("/");
        // }

        postAPI('/api/user/login', model)
            .then((response) => {
              errors.authentication = '';
              store.dispatch('login', response.data);
              store.commit('clearBusy')
              router.push("/");
            })
            .catch((error) => {
              errors.authentication = error.message;
              store.commit('clearBusy')
            })
        ;
      }

      if (!model.username) { errors.username = 'E-mail is verplicht.'; }
      if (!model.password) { errors.password = 'Wachtwoord is verplicht.'; }
    }

    return {
      model,
      onSubmit,
      errors,
    }
  }
}
</script>

<style lang="scss" scoped>
  .field {
    &--button {
      margin-top: 1.5rem;
    }
  }
</style>
