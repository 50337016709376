const state = {
  accessToken: '',
  isBusy: false,
  isBusyDownload: false,
  error: '',
  username: '',
}

const mutations = {
  setBusy: (state) => state.isBusy = true,
  clearBusy: (state) => state.isBusy = false,
  setError: (state, error) => state.error = error,
  clearError: (state) => state.error = "",
  setAccessToken: (state, accessToken) => {
    state.accessToken = accessToken;
  },
  clearAccessToken: (state) => {
    state.accessToken = "";
  },
  setUserName: (state, username) => {
    state.username = username;
  },
  setBusyDownload: (state) => state.isBusyDownload = true,
  clearBusyDownload: (state) => state.isBusyDownload = false,
}

const actions = {
  login: ({ commit }, user) => {
    let token = user.jwt_token;

    if (token.charAt(0) === 'b') {
      token = token.slice(0, -1);
      token = token.substring(2)
    }

    commit("clearError");
    commit('setUserName', user.name)
    commit('setAccessToken', token)

    let now = new Date();
    let expiryDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()+7);

    const authToken = {
      userName: user.name,
      token: token,
      expiry: expiryDate.getTime(),
    }

    localStorage.setItem('userAuth', JSON.stringify(authToken))
  },
  logout: ({ commit }) => {
    commit("clearToken");
    localStorage.removeItem('userAuth');
  }
}

const getters = {
  isAuthenticated: (state) => {
    return state.accessToken;
  },
  getUsername: (state) => state.username,
  getAccessToken: (state) => state.accessToken,
  getIsBusy: (state) => state.isBusy,
  getisBusyDownload: (state) => state.isBusyDownload,
  getError: (state) => state.error,
}

const userModule = {
  state,
  mutations,
  actions,
  getters
}

export default userModule;
