<template>
  <div class="navigation-tags tags" v-if="isOrderedResult">
    <router-link :to="{ name: 'pakbonnen-draft' }" class="tag is-primary is-rounded">Drafts</router-link>
    <router-link :to="{ name: 'pakbonnen' }" class="tag is-primary is-rounded">Verzonden bestellingen</router-link>
    <router-link :to="{ name: 'pakbonnen-invoiced' }" class="tag is-primary is-rounded">Gefactureerd</router-link>
    <router-link :to="{ name: 'pakbonnen-other' }" class="tag is-primary is-rounded">Retour verwerkt (nog niet gefactureerd)</router-link>
  </div>
  <div class="navigation-tags tags" v-else>
    <router-link :to="{ name: 'zichtzendingen-drafts' }" class="tag is-primary is-rounded">Drafts</router-link>
    <router-link :to="{ name: 'zichtzendingen' }" class="tag is-primary is-rounded">Verzonden zichtzendingen</router-link>
    <router-link :to="{ name: 'zichtzendingen-invoiced' }" class="tag is-primary is-rounded">Gefactureerd</router-link>
    <router-link :to="{ name: 'zichtzendingen-other' }" class="tag is-primary is-rounded">Retour verwerkt (nog niet gefactureerd)</router-link>
  </div>
</template>
<script>

export default {
  name: 'PackingSlipsTypeTabs',
  computed: {
    isOrderedResult() {
      return this.$route.name.includes('pakbonnen');
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-tags {
  padding: 2rem 0 1rem 0;

  a {
    padding: .75rem 1rem;
    font-weight: 500;
    &:hover {
      text-decoration: none !important;
    }

    &:not(.router-link-exact-active) {
      background: inherit !important;
      color: #A7A7A7 !important;
      transition: all .2s ease-in-out;
    }
  }
}
</style>
