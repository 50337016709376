const state = {
    featuredBooks: [],
    searchedBook: [],
}

const mutations = {
    setFeaturedBooks: (state, data) => {
        state.featuredBooks = data;
    },
    setSearchedBook: (state, data) => {
        state.searchedBook = data;
    },
    clearFeaturedBooks: (state) => state.featuredBooks = [],
    clearSearchedBook: (state) => state.searchedBook = [],
}

const actions = {
    fetchFeaturedBooks: ({commit}, data) => {
        commit("clearFeaturedBooks");
        let featured = [];

        data.forEach((book) => {
            // Remove books without cover image
            if (book['image'] === null) return;

            featured.push({
                'name': book['main_title'],
                'author': book['author'],
                'url': book['webshop_url'],
                'image': book['image'],
            });
        });

        commit('setFeaturedBooks', featured);
    },
}

const getters = {
    getFeaturedBooks: (state) => state.featuredBooks,
    getSearchedBook: (state) => state.searchedBook,
}

const cacheModule = {
    state,
    mutations,
    actions,
    getters
}

export default cacheModule;
