<template>
  <div class="content" id="invoices">
    <div class="results-header">
      <div class="results-header__title">
        <h3 class="title">Facturen</h3>
      </div>
      <InvoicesTabs/>
    </div>
    <div class="results-main">
      <InvoicesResultTable v-if="!isBusy" :data="data" />
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";
import InvoicesTabs from "@/app/Components/InvoicesTabs";
import InvoicesResultTable from "@/app/Components/InvoicesResultTable";

export default {
  name: 'Invoices',
  components: {
    InvoicesResultTable,
    InvoicesTabs,
    LoadingBlock,
    BackButton,
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/invoices')
          .then((response) => {
            data.value = response.data;
            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });

    return {
      data,
    }
  }
};

</script>
<style lang="scss">
#invoices {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
