import { createStore } from "vuex";
import user from './modules/user.js';
import cache from "./modules/cache";

export default createStore({
  modules: {
    user,
    cache
  },
});
