<template>
  <div class="box pdf-download" v-if="data['packing_slips']">
    <a @click="downloadPdf(data)" class="is-primary is-inverted button m-0 pdf-download__button" v-bind:class="{'is-loading': isBusyDownload}">
      <font-awesome-icon icon="download"/>
      PDF
    </a>
  </div>

  <table class="table is-hoverable is-fullwidth result-table">
    <thead>
    <tr>
      <th style="width: 10%">Factuurnummer</th>
      <th style="width: 20%">Klant</th>
      <th style="width: 20%">Leveringsadres</th>
      <th style="width: 10%">Factuurdatum</th>
      <th style="width: 10%">Vervaldatum</th>
      <th style="width: 20%">Bron documenten</th>
      <th style="width: 10%">Totaal</th>
    </tr>
    </thead>
    <tbody>
      <tr class="result-table__item" v-if="!data['uuid']">
        <td colspan="7">Geen gegevens gevonden.</td>
      </tr>
      <tr class="result-table__item" @click="goToInvoiceDetail(data['uuid'])" v-else>
        <td>{{ data['invoice_number'] }}</td>
        <td>{{ data['client'] }}</td>
        <td>{{ data['delivery_address'] }}</td>
        <td>{{ data['invoice_date'] }}</td>
        <td>{{ data['due_date'] }}</td>
        <td>{{ data['origin_document'] }}</td>
        <td> &euro;{{ data['total'] }}</td>
      </tr>
    </tbody>
  </table>

  <table class="table is-hoverable is-fullwidth result-table" v-if="data['invoice_lines']">
    <thead>
    <tr>
      <th style="width: 40%">Omschrijving</th>
      <th style="width: 30%">Hoeveelheid</th>
      <th style="width: 15%">Eenheidsprijs</th>
      <th style="width: 15%">Bedrag</th>
    </tr>
    </thead>
    <tbody>
    <tr class="result-table__item" v-if="!data['uuid']">
      <td colspan="7">Geen gegevens gevonden.</td>
    </tr>
    <template v-if="data['invoice_type'] === 'invoice'">
      <tr class="table__item" v-for="(invoice, index) in data['invoice_lines']" v-bind:key="invoice" @click="goToDetail(data['packing_slips'][index]['uuid'])">
        <td v-if="data['packing_slips'][index]">
          {{ data['packing_slips'][index]['name'] }}
        </td>
        <td v-else></td>
        <td> {{ invoice['quantity'] }}</td>
        <td> &euro;{{ invoice['unit_price'] }}</td>
        <td> &euro;{{ invoice['total_price'] }}</td>
      </tr>
    </template>
    <template v-else>
      <tr class="table__item" v-for="book in data['invoice_lines']" v-bind:key="book">
        <td>
          <template v-if="book['boek_naam']">{{ book['boek_naam'] }}</template>
        </td>
        <td> {{ book['quantity'] }}</td>
        <td> &euro;{{ book['unit_price'] }}</td>
        <td> &euro;{{ book['total_price'] }}</td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import {downloadInvoiceAPI} from "@/utils/useApi";

export default {
  name: "InvoicesDetailTable",
  props: {
    data: Object
  },
  computed: {
    isBusyDownload() {
      return this.$store.getters.getisBusyDownload;
    },
  },
  methods: {
    goToDetail(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
    goToInvoiceDetail(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=account.invoice&menu_id=298&action=391';
      window.open(url, '_blank');
    },
    downloadPdf(data) {
      downloadInvoiceAPI(data);
    }
  },
}
</script>

<style lang="scss" scoped>
.pdf-download {
  a {
    color: $primary;
    transition: all .2s ease-in;

    &:hover {
      color: #363636;
    }
  }

  svg {
    margin-right: .5rem;
  }
}
.button.is-primary.is-loading.pdf-download__button {
  &::after {
    border-color: transparent transparent $primary $primary !important;
  }
}
</style>
