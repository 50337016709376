<template>
  <div class="portal__wrapper">
    <NavigationModal v-bind:class="{'is-active': showMobileNavigation}"/>
    <section class="portal__content columns">
      <Navigation/>
      <section class="portal__body" :class="classes">
        <NavBar v-on:toggleMobileNav="toggleMobileNav"/>
        <router-view/>
      </section>
    </section>
  </div>
</template>
<script>

import Navigation from "../Components/Navigation/Navigation";
import NavBar from "../Components/Navigation/NavBar";
import NavigationModal from "../Components/Navigation/NavigationModal";
import {useRoute} from "vue-router";

export default {
  name: 'Base',
  components: {
    NavigationModal,
    Navigation,
    NavBar,
  },
  data() {
    return {
      showMobileNavigation: false,
    };
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNavigation = !this.showMobileNavigation;
    },
  },
  computed: {
    classes() {
      const route = useRoute();
      let classes = 'column'
      if (route.path === '/' || route.name === 'books-detail') {
        classes  += ' has-bg';
      }
      return classes;
    }
  },
  setup() {
    let isMobile = false;

    if (screen.width < 1025) {
      isMobile = true;
    }

    return {
      isMobile,
    }
  }
};
</script>
<style lang="scss">
// General
.portal {
  &__wrapper {
    height: 100%;
    display: flex;
  }

  &__content {
    width: 100%;
    margin: 0 !important;
  }

  &__body {
    overflow: hidden;
    padding: 0 !important;
  }
}
.control > .button > svg {
  margin-left: 0 !important;
}
.has-bg {
  background: $darker-gray;
}

// Table
.result-table {
  border-radius: 5px;
  margin-bottom: 2rem !important;

  &__group {
    font-size: 1rem;
    font-weight: 600;

    svg {
      margin-left: .5rem;
    }
  }

  td, th {
    border: solid #e5e5e5;
    border-width: 1px 0;
    padding: .5rem .75rem;
  }

  tbody tr {
    cursor: pointer;
  }

  tr:hover {
    a {
      color: $primary;

      path {
        fill: $primary;
      }
    }
  }

  td {
    padding: 1rem !important;
    font-size: .9rem;

    a {
      color: $black;
      text-decoration: underline;
      svg {
        transition: all .4s ease-in-out;
        margin-left: 1rem;
      }
      path {
        fill: $black;
      }
    }
  }

  th {
    border-top: none;
    padding: 1.5rem 1rem !important;
    font-weight: 500;
    color: #000000;
  }
}
</style>
