<template>
  <div class="tile box is-primary is-child">
    <p class="title">{{ title }}</p>
    <p class="description">{{ description }}</p>
    <router-link :to="route" class="button is-primary">
      {{ cta }}
      <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z" fill="white"/>
      </svg>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "SectionBlock",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    cta: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  display: flex !important;
  flex-direction: column;
  .description {
    margin-bottom: 33px;
  }
  .button {
    margin-top: auto;
    font-weight: 500;
    width: 100%;
    justify-content: flex-start;

    svg {
      transition: all .4s ease-in-out;
      margin-left: 1rem;
    }

    &:hover {
      svg {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
