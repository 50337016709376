<template>
  <div class="content" id="sales-order">
    <ResultsHeader title="Bestellingen (per order)" />
    <div class="results-main">
      <table class="table is-hoverable is-fullwidth result-table" v-if="!isBusy">
        <thead>
        <tr>
          <th style="width: 30%;">Klant - Perceel [Pakbon]</th>
          <th>Order nummer</th>
          <th>Datum</th>
          <th>Referentie</th>
        </tr>
        </thead>

        <template v-for="(result, key) in data" v-bind:key="key">
          <tbody class="result-table__group">
          <tr @click="openResultGroup(key)">
            <td :colspan="4">
              {{ key }} ({{ getAmount(result) }})
              <font-awesome-icon icon="caret-up" v-if="resultGroupsStatus[key]"/>
              <font-awesome-icon icon="caret-down" v-else/>
            </td>
          </tr>
          </tbody>
          <tbody v-if="resultGroupsStatus[key]">
            <tr v-for="order in result" v-bind:key="order" class="result-table__item" @click="goToDetail(order.id)">
              <td>{{ order.customer_parcel }}</td>
              <td>{{ order.order }}</td>
              <td>{{ formatDate(order.confirmation_date) }}</td>
              <td v-if="order.library_ref">{{ order.library_ref }}</td>
              <td v-else></td>
            </tr>
          </tbody>
        </template>
      </table>
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import ResultsHeader from "@/app/Components/ResultsHeader";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";

export default {
  name: 'SalesOrder',
  components: {
    LoadingBlock,
    BackButton,
    ResultsHeader
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    }
  },
  methods: {
    openResultGroup(key) {
      this.resultGroupsStatus[key] = !this.resultGroupsStatus[key];
    },
    getAmount(parcel) {
      return parcel.length;
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      return date.toLocaleDateString();
    },
    goToDetail(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=sale.order&menu_id=294&action=390';
      window.open(url, '_blank');
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/sale_orders')
          .then((response) => {
            let results = response.data;

            const groupBy = function (data, key) {
              return data.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };

            data.value = groupBy(results, 'customer_parcel');
            console.debug(data.value);
            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });
    return {
      data,
    }
  }
};

</script>
<style lang="scss">
#sales-order {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
