import axios from 'axios';
import { ref } from 'vue';
import store from '../store'
import download from 'downloadjs';

axios.defaults.crossDomain = true;


export function postAPI(route, data) {
     return axios.post(route, data)
        .catch((error) => {
            throw {
                status: error.response.status,
                message: error.response.data.message
            };
        });
}

export function getAPI(route) {
    const access_token = store.getters.getAccessToken;

    return axios.get(route, {headers: {'API-TOKEN': access_token}})
        .catch((error) => {
            throw {
                status: error.response.status,
                message: error.response.data.message
            };
        });
}

export function downloadInvoiceAPI(data) {
    store.commit('setBusyDownload');
    const access_token = store.getters.getAccessToken;
    const type = data['invoice_type'] === 'invoice' ? 'factuur' : 'creditfactuur';
    const fileName = `${type}-${data['invoice_number']}`.toLowerCase();

    axios.get('/api/invoices/' + data['uuid'] + '/pdf', {
        headers: {'API-TOKEN': access_token},
        responseType: 'blob',
    })
        .then((response) => {
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'}
            );
            download(file, fileName);
            store.commit('clearBusyDownload');
        })
        .catch((error) => {
            store.commit('clearBusyDownload');
            throw {
                status: error.response.status,
                message: error.response.data.message
            };
        });
}

export const useApi = () => {
  const access_token = store.getters.getAccessToken;
  const baseURL = process.env.VUE_APP_BASE_URL + 'api/v1/';

  const data = ref();
  const error = ref();

  const api = axios.create({
    baseURL: baseURL,
  })

  const post = (route, payload) => {
    return api.post(baseURL + route, payload)
        .then(response => {
          return response.data;
        }).catch(error => {
          return error.response.value;
        })
    ;
  }

  const get = (route) => {
    store.commit('setBusy');
    return api.get(baseURL + route)
        .then(response => {
          return response.data;
        }).catch(error => {
          return error.response.value;
        })
  }

    /**
     * Invoice download on invoice detail page
     * @param data
     */
  const getInvoiceDownload = (data) => {
      const id = data['uuid'];
      let fileName = data['invoice_number'] + '-' + data['invoice_date'];

      fileName = fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.pdf';

      axios({
          url: baseURL + 'invoices/' + id + '/pdf',
          method: 'GET',
          responseType: 'blob',
          headers: {
              'API-TOKEN': access_token,
          }
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
      });
  }

  return {
    data,
    error,
    post,
    get,
    getInvoiceDownload
  }
}
