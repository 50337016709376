export const getStatus = (status) => {
    switch (status) {
        case 'returned':
            return 'Retour verwerkt';
        case 'invoiced':
            return 'Gefactureerd';
        case 'done':
            return 'Verzonden';
        case 'draft':
            return 'Draft';
        default:
            return 'Besteld';
    }
}
