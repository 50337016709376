<template>
  <div class="content" id="search">
    <div class="results-header">
      <div class="navbar-menu">
        <div class="navbar-start">
          <div class="results-header__title">
            <h3 class="title">
              <span>Zoekresultaten voor: </span>
              {{ query }}
            </h3>
          </div>
        </div>
        <div class="navbar-end">
          <div class="field has-addons">
            <p class="control">
                <span class="select">
                  <select v-model="input.searchType">
                    <option value="title">Title</option>
                    <option value="isbn">ISBN</option>
                  </select>
                </span>
            </p>
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Zoeken..." v-model="input.searchQuery" v-on:keyup.enter="search">
            </p>
            <p class="control">
              <a class="button is-primary" v-on:click="search">
                <font-awesome-icon icon="search"/>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="results-main">
      <BooksResultTable v-if="!isBusy" :data="data" />
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";
import {useRoute, useRouter} from "vue-router";
import {reactive} from "@vue/reactivity";
import BooksResultTable from "@/app/Components/BooksResultTable";

export default {
  name: 'SearchBooks',
  components: {
    BooksResultTable,
    LoadingBlock,
    BackButton,
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();
    const route = useRoute();
    const input = reactive({searchQuery: "", searchType: "invoice"});
    const query = ref('');
    const router = useRouter()

    const queryId = route.query.id ? decodeURIComponent(route.query.id) : null;
    const queryType = route.query.type ? decodeURIComponent(route.query.type) : null;

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      input.searchType = queryType;
      input.searchQuery = queryId;
      query.value = queryId;

      if (queryType === 'title') {
        getAPI('/api/books/search/title/' + encodeURIComponent(queryId))
            .then((response) => {
              data.value = response.data
              store.commit('clearBusy');
            })
            .catch((error) => {
              if (error.status === 404) {
                data.value = null;
              }
              // TODO Error handling
              store.commit('clearBusy');
            });
      } else {
        getAPI('/api/books/search/isbn/' + encodeURIComponent(queryId))
            .then((response) => {
              data.value = response.data
              store.commit('clearBusy');
            })
            .catch((error) => {
              if (error.status === 404) {
                data.value = null;
              }
              // TODO Error handling
              store.commit('clearBusy');
            });
      }
    });

    function search() {
      if (input.searchQuery && input.searchType) {
        store.commit('setBusy');
        query.value = input.searchQuery;

        // Set route
        router.push({name: 'books-search', query: {'type': input.searchType, 'id': input.searchQuery}});

        if (input.searchType === 'title') {
          getAPI('/api/books/search/title/' + encodeURIComponent(input.searchQuery))
              .then((response) => {
                data.value = response.data
                store.commit('clearBusy');
              })
              .catch((error) => {
                if (error.status === 404) {
                  data.value = null;
                }
                // TODO Error handling
                store.commit('clearBusy');
              });
        } else {
          getAPI('/api/books/search/isbn/' + encodeURIComponent(input.searchQuery))
              .then((response) => {
                data.value = response.data
                store.commit('clearBusy');
              })
              .catch((error) => {
                if (error.status === 404) {
                  data.value = null;
                }
                // TODO Error handling
                store.commit('clearBusy');
              });
        }
      }
    }

    return {
      data,
      query,
      input,
      search
    }
  }
};

</script>
<style lang="scss">
#search {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
