<template>
  <router-link class="btn-back" :to='path=route'>
    <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23743 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.989591 6.3033 0.696697C6.01041 0.403804 5.53553 0.403804 5.24264 0.696697L0.469669 5.46967ZM26 5.25L1 5.25L1 6.75L26 6.75L26 5.25Z" fill="white"/>
    </svg>
    Terug
  </router-link>
</template>
<script>

export default {
  name: 'BackButton',
  props: {
    route: {
      type: String,
      required: false,
      default: '/'
    },
  },
};

</script>
<style lang="scss">

.btn-back {
  color:  #A7A7A7;
  cursor: pointer;

  &:hover {
    color: $primary;

    path {
      fill: $primary;
    }
  }

  svg {
    margin-right: 1rem;

    path {
      fill: #A7A7A7;
    }
  }
}
</style>
