import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
import Base from "@/app/Layouts/Base";
import Homepage from "@/app/Views/Homepage";
import ToDeliver from "@/app/Views/ToDeliver";
import Search from "@/app/Views/Search";
import PackingSlipsLastDelivery from "@/app/Views/PackingSlipsLastDelivery";
import PackingSlips from "@/app/Views/PackingSlips";
import NextDelivery from "@/app/Views/NextDelivery";
import Invoices from "@/app/Views/Invoices";
import SearchInvoices from "@/app/Views/SearchInvoices";
import InvoiceDetail from "@/app/Views/InvoiceDetail";
import BudgetControl from "@/app/Views/BudgetControl";
import SearchBooks from "@/app/Views/SearchBooks";
import BookDetail from "@/app/Views/BookDetail";
import Auth from "@/app/Layouts/Auth";
import SalesOrder from "@/app/Views/SalesOrder.vue";

const authGuard = () => {
  if (!store.getters.isAuthenticated && !checkLocalStorage()) {
    router.push('/login');
  }
};

const checkLocalStorage = () => {
  let savedAuth = localStorage.getItem('userAuth');

  if (savedAuth) {
    savedAuth = JSON.parse(savedAuth);

    // If token is not expired, save in store
    let now = new Date();
    if (now.getTime() < savedAuth.expiry) {
      store.commit('setAccessToken', savedAuth.token);
      store.commit('setUserName', savedAuth.userName);

      return true;
    }
  }
  return false;
}

const routes = [
  {
    path: '/',
    component: Base,
    beforeEnter: authGuard,
    children: [
      {
        path: '/',
        alias: 'homepage',
        component: Homepage,
        name: 'Homepage',
        meta: { description: 'Homepage' },
      },
      {
        path: '/boeken/te-leveren',
        alias: 'te-leveren',
        component: ToDeliver,
        name: 'ToDeliver',
        meta: { description: 'Nog te leveren boeken' },
      },
      {
        path: '/boeken/detail/:id',
        component: BookDetail,
        name: 'books-detail',
      },
      {
        path: '/boeken/volgende-levering',
        component: NextDelivery,
        name: 'next-delivery',
      },
      {
        path: '/boeken/search',
        component: SearchBooks,
        name: 'books-search',
      },
      {
        path: '/zoeken',
        alias: 'zoeken',
        component: Search,
        name: 'Search',
      },
      {
        path: '/pakbon/laatste-levering',
        component: PackingSlipsLastDelivery,
        name: 'PackingSlipLastDelivery',
      },
      {
        path: '/pakbonnen',
        alias: 'pakbonnen',
        component: PackingSlips,
        name: 'pakbonnen',
        meta: { description: 'Pakbonnen' },
      },
      {
        path: 'draft',
        alias: 'pakbonnen-draft',
        component: PackingSlips,
        name: 'pakbonnen-draft',
        meta: { description: 'Pakbonnen draft' },
      },
      {
        path: 'invoiced',
        alias: 'pakbonnen-invoiced',
        component: PackingSlips,
        name: 'pakbonnen-invoiced',
        meta: { description: 'Pakbonnen invoiced' },
      },
      {
        path: 'other',
        alias: 'pakbonnen-other',
        component: PackingSlips,
        name: 'pakbonnen-other',
        meta: { description: 'Pakbonnen other' },
      },
      {
        path: 'zichtzendingen',
        alias: 'zichtzendingen',
        component: PackingSlips,
        name: 'zichtzendingen',
        meta: { description: 'Zichtzendingen' },
      },
      {
        path: 'zichtzendingen/drafts',
        alias: 'zichtzendingen-drafts',
        component: PackingSlips,
        name: 'zichtzendingen-drafts',
        meta: { description: 'Zichtzendingen drafts' },
      },
      {
        path: 'zichtzendingen/invoiced',
        alias: 'zichtzendingen-invoiced',
        component: PackingSlips,
        name: 'zichtzendingen-invoiced',
        meta: { description: 'Zichtzendingen invoiced' },
      },
      {
        path: 'zichtzendingen/other',
        alias: 'zichtzendingen-other',
        component: PackingSlips,
        name: 'zichtzendingen-other',
        meta: { description: 'Zichtzendingen other' },
      },

      {
        path: '/invoices',
        component: Invoices,
        name: 'invoices',
      },
      {
        path: '/invoices/credit',
        component: Invoices,
        name: 'invoices-credit',
      },
      {
        path: '/invoices/search',
        component: SearchInvoices,
        name: 'invoices-search',
      },
      {
        path: '/invoices/detail/:id',
        component: InvoiceDetail,
        name: 'invoices-detail',
      },
      {
        path: '/budget-controle',
        component: BudgetControl,
        name: 'budgetControle',
      },
      {
        path: '/verkooporders',
        component: SalesOrder,
        name: 'verkooporders',
      },
    ]
  },
  {
    path: '/login',
    component: Auth,
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
