<template>
  <div class="box" v-if="data['main_title']">
    <h3 class="title">{{ data['main_title'] }} [{{ data['isbn'] }}]</h3>
    <p class="subtitle mt-1">{{ data['author'] }}<template v-if="data['co_author']">, {{ data['co_author'] }}</template></p>
  </div>

  <table class="table is-hoverable is-fullwidth result-table">
    <thead>
    <tr>
      <th>Status</th>
      <th>Datum bestelling</th>
      <th>Perceel</th>
      <th>Pakbon</th>
      <th>Type</th>
      <th>Besteld</th>
      <th>Geleverd</th>
      <th>Gescand</th>
      <th v-if="details['qty_retour'] > 0">Retour</th>
    </tr>
    </thead>
    <tbody>
      <tr class="result-table__item" v-if="!data['uuid']">
        <td colspan="7">Geen gegevens gevonden.</td>
      </tr>
      <tr class="result-table__item" v-else>
        <td>{{ getPackageSlipStatus(details['status']) }}</td>
        <td v-if="details['reservation_date']">{{ details['reservation_date'] }}</td>
        <td v-else>/</td>
        <td>{{ details['customer_parcel'] }}</td>
        <td v-if="details['packing_slip_uuid']">
          <a @click="goToPackingSlip(details['packing_slip_uuid'])" class="url">
            {{ details['packing_slip'] }}
          </a>
        </td>
        <td v-else>/</td>
        <td v-if="details['sight_sending']">Zichtzending</td>
        <td v-else>Bestelling</td>
        <td>{{ details['ordered'] }}</td>
        <td>{{ details['delivered'] }}</td>
        <td>{{ details['scanned'] }}</td>
        <td v-if="details['returned'] > 0">{{ details['returned'] }}</td>
      </tr>
    </tbody>
  </table>

  <div class="box webshop-url" v-if="data['webshop_url']">
    <a @click="goToWebshop(data['webshop_url'])" class="title">
      Bekijk dit boek in onze webshop
      <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z" fill="white"/>
      </svg>
    </a>
  </div>

</template>

<script>
import {getStatus} from "@/utils/utils";

export default {
  name: "BooksDetailTable",
  props: {
    data: Object,
    details: Object
  },
  methods: {
    getPackageSlipStatus(status) {
      return getStatus(status)
    },
    goToWebshop(url) {
      window.open(url, '_blank');
    },
    goToPackingSlip(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
  },
}
</script>

<style lang="scss" scoped>
.webshop-url {
  height: fit-content;
  a {
    font-size: 1rem;

    svg {
      transition: all .4s ease-in-out;
      margin-left: 1rem;
    }

    path {
      fill: $primary;
    }

    &:hover {
      svg {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
