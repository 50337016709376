<template>
  <div class="navbar-menu">
    <div class="navbar-start nav-tabs">
      <router-link :to="{ name: 'invoices' }" class="navbar-item nav-tabs__item" v-bind:class="{'is-active': isCreditInvoices}">
        Facturen
      </router-link>
      <router-link :to="{ name: 'invoices-credit' }" class="navbar-item nav-tabs__item" v-bind:class="{'is-active': !isCreditInvoices}">
        Creditfacturen
      </router-link>
    </div>
    <div class="navbar-end">
      <div class="field has-addons">
        <p class="control">
                <span class="select">
                  <select v-model="input.searchType">
                    <option value="packingslip">Pakbonnummer</option>
                    <option value="invoice" selected>Factuurnummer</option>
                  </select>
                </span>
        </p>
        <p class="control is-expanded">
          <input class="input" type="text" placeholder="Zoeken..." v-model="input.searchQuery" v-on:keyup.enter="search">
        </p>
        <p class="control">
          <a class="button is-primary" v-on:click="search">
            <font-awesome-icon icon="search"/>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>

import { reactive } from '@vue/reactivity';
import { useRouter } from "vue-router";

export default {
  name: 'InvoicesTabs',
  computed: {
    isCreditInvoices() {
      return this.$route.name === 'invoices';
    }
  },
  setup() {
    const router = useRouter()
    const input = reactive({searchQuery: "", searchType: "invoice"});

    function search() {
      router.push({name: 'invoices-search', query: {'type': input.searchType, 'id': input.searchQuery}});
    }

    return {
      search,
      input,
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-menu {
  padding-bottom: 0;

  .nav-tabs {

    &__item {
      text-align: center;
      position: relative;
      font-weight: 500;
      color: #A7A7A7;
      padding: 1rem 2.5rem !important;

      &:hover {
        color: $primary !important;
      }
    }

    .is-active {
      color: $primary;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        width: 100%;
        border-radius: 5px 5px 0 0;
        background: $primary;
      }
    }
  }
}
</style>
