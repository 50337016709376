import { createApp } from 'vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload, faCaretDown, faCaretUp, faInfo, faHome, faBox, faStore, faListUl, faBook, faBuilding, faUserCircle, faPhone, faEnvelope, faArchive, faMapMarkedAlt, faUser, faSignOutAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

import store from './store'
import router from './router'
import App from './App.vue'
library.add(faDownload, faCaretDown, faCaretUp, faInfo, faHome, faBox, faStore, faListUl, faBook, faBuilding, faUserCircle, faPhone, faEnvelope, faArchive, faMapMarkedAlt, faUser, faSignOutAlt, faSearch);

require('./assets/main.scss');

createApp(App).use(store)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
