<template>
  <div class="content" id="search">
    <ResultsHeader :title="id" prefix="Zoekresultaten voor: " />
    <div class="results-main">
      <table class="table is-hoverable is-fullwidth result-table" v-if="!isBusy">
        <thead>
        <tr>
          <th>Klant - Perceel</th>
          <th>Pakbon</th>
          <th>Datum verzending</th>
        </tr>
        </thead>
        <tbody>
          <tr class="result-table__item" v-if="data['customer_parcel']" @click="goToDetail(data['uuid'])">
            <td>{{ data['customer_parcel'] }}</td>
            <td>{{ data['name'] }}</td>
            <td>{{ data['reservation_date'] }}</td>
          </tr>
          <tr v-else>
            <td colspan="3">Geen gegevens gevonden.</td>
          </tr>
        </tbody>
      </table>
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import ResultsHeader from "@/app/Components/ResultsHeader";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";
import {useRoute} from "vue-router";

export default {
  name: 'Search',
  components: {
    LoadingBlock,
    BackButton,
    ResultsHeader
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  methods: {
    goToDetail(id) {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();
    const route = useRoute();

    const type = route.query.type;
    let id = route.query.id;

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      if (type === 'pakbon') {
        id = id.toLowerCase();
        id = id.startsWith('pakb') ? id.substring(4) : id;

        getAPI('/api/packing_slips/' + id)
            .then((response) => {
              data.value = response.data
              store.commit('clearBusy');
            })
            .catch((error) => {
              if (error.status === 404) {
                data.value = null;
              }
              // TODO Error handling
              store.commit('clearBusy');
            });
      }
    });

    return {
      data,
      id
    }
  }
};

</script>
<style lang="scss">
#search {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
