<template>
  <div class="tile is-primary is-child" id="packing-order">
    <div class="box">
      <p class="title">Zoek een pakbon op</p>
      <p class="description"></p>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input class="input" type="text" placeholder="Zoeken..." v-model="input.searchInput" v-on:keyup.enter="search">
        </p>
        <p class="control">
          <a class="button is-primary" v-on:click="search">
            <font-awesome-icon icon="search"/>
          </a>
        </p>
      </div>
    </div>
    <div class="box">
      <router-link :to="{ name: 'PackingSlipLastDelivery' }"  class="button is-primary">
        Pakbon(nen) van laatste levering
        <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z" fill="white"/>
        </svg>
      </router-link>
      <router-link :to="{ name: 'pakbonnen' }" class="button is-outlined is-primary">
        Alle pakbonnen van bestellingen
        <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z" fill="white"/>
        </svg>
      </router-link>
      <router-link :to="{ name: 'zichtzendingen' }" class="button is-outlined is-primary">
        Alle pakbonnen van zichtzendingen
        <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z" fill="white"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { useRouter } from "vue-router";

export default {
  name: "PackingOrderBlock",
  setup() {
    const router = useRouter()
    const input = reactive({searchInput: ""});

    function search() {
      if (input.searchInput) {
        let searchQuery = input.searchInput;
        router.push({ name: 'Search', query: { type: 'pakbon', id: searchQuery } })
      }
    }

    return {
      search,
      input,
    }
  }
}
</script>

<style lang="scss" scoped>
#packing-order {
  .box:first-child {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: .5px solid rgba(167, 167, 167, 0.25);
    padding-bottom: 2rem;
  }
  .box:last-child {
    padding-top: 2rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.box {
  .description {
    margin-bottom: 33px;

    span {
      color: $dark-grey;
    }
  }

  .select::after {
    border-color: #A7A7A7;
  }

  .button {
    font-weight: 500;
    width: 100%;
    margin-bottom: 1.25rem;
    justify-content: flex-start;

    svg {
      transition: all .4s ease-in-out;
      margin-left: 1rem;
    }

    &.is-outlined {
      svg path {
        fill: $primary;
      }
    }

    &:hover {
      &.is-outlined {
        svg path {
          fill: $white;
        }
      }
      svg {
        margin-left: 1.5rem;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
