<template>
  <div class="content" id="packing-order">
    <ResultsHeader title="Boeken in volgende levering" />
    <div class="results-main">
      <table class="table is-hoverable is-fullwidth result-table" v-if="!isBusy">
        <thead>
        <tr>
          <th style="width: 30%;">Klant - Perceel [Pakbon]</th>
          <th>Product naam</th>
          <th>ISBN</th>
          <th>Referentie</th>
          <th>Order</th>
          <th style="width: 7%">Totaal</th>
        </tr>
        </thead>

        <template v-for="(result, key) in data" v-bind:key="key">
          <tbody class="result-table__group">
          <tr @click="openResultGroup(key)">
            <td :colspan="6">
              {{ result.customer_parcel }}&nbsp;[{{ result.name }}]
              <font-awesome-icon icon="caret-up" v-if="resultGroupsStatus[key]"/>
              <font-awesome-icon icon="caret-down" v-else/>
            </td>
          </tr>
          </tbody>
          <tbody v-if="resultGroupsStatus[key]">
          <tr v-for="book in result.lines" v-bind:key="book" class="result-table__item">
            <td>{{ result.customer_parcel }}&nbsp;[{{ result.name }}]</td>
            <td>{{ book['product_name'] }}</td>
            <td>{{ book['barcode'] }}</td>
            <td>{{ book['library_ref'] }}</td>
            <td>{{ book['order'] }}</td>
            <td>{{ book['qty_to_deliver'] }}</td>
          </tr>
          </tbody>
        </template>
      </table>
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import ResultsHeader from "@/app/Components/ResultsHeader";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";

export default {
  name: 'ToDeliver',
  components: {
    LoadingBlock,
    BackButton,
    ResultsHeader
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  methods: {
    openResultGroup(key) {
      this.resultGroupsStatus[key] = !this.resultGroupsStatus[key];
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      getAPI('/api/next_delivery')
          .then((response) => {
            data.value = response.data
            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });

    return {
      data,
    }
  }
};

</script>
<style lang="scss">
#packing-order {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
