<template>
  <div class="results-header">
    <div class="results-header__title">
      <h3 class="title">
        <span>{{ prefix }}</span>
        {{ title }}
      </h3>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ResultsHeader',
  props: {
    title: {
      required: true
    },
    prefix: {
      type: String,
      required: false
    },
  },
};

</script>
<style lang="scss">
.results-header {
  padding:        0 2rem;
  background:     $white;
  display:        flex;
  flex-direction: column;

  &__title {
    padding-bottom: 1rem;
    span {
      margin-right: .5rem;
      font-size:    1rem;
      font-weight:  normal;
    }
  }
}
</style>
