<template>
  <div id="mobile-menu">
    <router-link to="/">
      <img src="@/assets/images/logo.svg" alt="Distri-Bib">
    </router-link>
    <div class="menu-right">
      <p>
        Welkom terug, <span>{{ username }}sdf</span>
      </p>
      <div class="menu-toggle">
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" v-on:click="toggleNavigation" v-bind:class="{'is-active': showNavigation}">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
  <div :class="classes">
    <div class="navbar-item navbar-end">
      <div class="is-hidden-mobile">
        <p class="navbar-subtitle">Welkom terug</p>
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <a class="navbar-link" aria-haspopup="true" aria-controls="dropdown-menu">
              {{ username }}
            </a>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <a class="navbar-item" @click="profileAction">
                  <font-awesome-icon icon="user" class="navbar-icon"/> Account
                </a>
                <a class="navbar-item" @click="logoutAction">
                  <font-awesome-icon icon="sign-out-alt" class="navbar-icon"/> Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "../../../store";
import { useRoute } from 'vue-router'
import router from "../../../router";

export default {
  name: "NavBar",
  emits: ["toggleMobileNav"],
  data() {
    return {
      username: '',
      showNavigation: false,
    }
  },
  mounted() {
    this.username = store.getters.getUsername;
  },
  methods: {
    toggleNavigation() {
      this.showNavigation = !this.showNavigation;
      this.$emit('toggleMobileNav');
    },
    logoutAction() {
      store.dispatch('logout');
      router.push("/");
      location.reload();
    },
    profileAction() {
      const url =  process.env.VUE_APP_BASE_URL + 'web?#view_type=list&model=res.partner&menu_id=336&action=387';
      window.open(url, '_blank');
    }
  },
  computed: {
    classes() {
      const route = useRoute();
      let classes = 'navbar'
      if (route.path === '/' || route.name === 'books-detail') {
        classes  += ' navbar--transparant';
      }
      return classes;
    }
  },
}
</script>

<style lang="scss" scoped>

.navbar {
  display: none;
  padding: 1rem .75rem 0 .75rem;
  @include xl() {
    display: block;
  }

  &--transparant {
    background: #F5F6FA !important;
  }

  &-subtitle,
  &-link {
    font-weight: 500;
    font-size: .9rem;
    display: block;
  }

  &-link {
    padding: 0 0.75rem;
    &:hover {
      color: #4a4a4a;
      background: none;
    }
  }

  &-subtitle {
    display: block;
    padding: 0 0.75rem;
    color: $primary;
    font-size: .8rem;
  }

  &-item {
    //padding: 0 1rem;
  }

  &-icon {
    margin-right: 10px;
  }

  .dropdown-item,
  .dropdown-content {
    padding: 0;
  }
}

#mobile-menu {
  @include xl() {
    display: none;
  }

  display: flex;
  align-items: center;

  .menu-right {
    display: flex;
    align-items: center;
    margin-left: auto;

    p {
      color: $primary;
      font-size: .8rem;

      span {
        color: $black;
        font-weight: 500;
      }
    }

    .navbar-burger {
      height: 3rem;
      display: block;
    }
  }
}
</style>
