<template>
  <div class="content" id="packing-slips">
    <ResultsHeader title="Pakbon(nen) van laatste levering" />
    <div class="results-main">
      <table class="table is-hoverable is-fullwidth result-table" v-if="!isBusy">
        <thead>
        <tr>
          <th style="width: 50%">Klant - Perceel</th>
          <th>Pakbon</th>
          <th>Datum verzending</th>
        </tr>
        </thead>
        <template v-for="(result, key) in data" v-bind:key="key">
          <tbody class="result-table__group">
          <tr @click="openResultGroup(key)">
            <td :colspan="6">
              {{ key }}
              <font-awesome-icon icon="caret-up" v-if="resultGroupsStatus[key]"/>
              <font-awesome-icon icon="caret-down" v-else/>
            </td>
          </tr>
          </tbody>
          <tbody v-if="resultGroupsStatus[key]">
          <tr v-for="packingSlip in result" v-bind:key="packingSlip" class="result-table__item" @click="goToDetail(packingSlip['uuid'])">
            <td>{{ packingSlip['customer_parcel'] }}</td>
            <td>{{ packingSlip['name'] }}</td>
            <td>{{ packingSlip['reservation_date'] }}</td>
          </tr>
          </tbody>
        </template>
      </table>
      <LoadingBlock v-else />
      <BackButton />
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import ResultsHeader from "@/app/Components/ResultsHeader";
import BackButton from "@/app/Components/BackButton";
import LoadingBlock from "@/app/Components/LoadingBlock";

export default {
  name: 'PackingSlipsLastDelivery',
  components: {
    LoadingBlock,
    BackButton,
    ResultsHeader
  },
  data() {
    return {
      resultGroupsStatus: {},
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  methods: {
    openResultGroup(key) {
      this.resultGroupsStatus[key] = !this.resultGroupsStatus[key];
    },
    goToDetail(id) {
      // TODO Check other detail actions
      const url = process.env.VUE_APP_BASE_URL + 'web?#id=' + id + '&view_type=form&model=stock.reservation&menu_id=295&action=388';
      window.open(url, '_blank');
    },
  },
  setup() {
    const data = ref([]);
    const store = useStore();

    // Fetch data
    onMounted(async () => {
      store.commit('setBusy');

      // if (process.env.NODE_ENV) {
      //   console.debug('USE MOCK DATA');
      //   let results = [{"uuid":18105,"name":"PAKB18105","customer_parcel":"Bibliotheek TEST -  Jeugd non-fictie","sight_sending":false,"status":"draft","reservation_date":null},{"uuid":18104,"name":"PAKB18104","customer_parcel":"Bibliotheek TEST -  Jeugd fictie","sight_sending":false,"status":"draft","reservation_date":null},{"uuid":18103,"name":"PAKB18103","customer_parcel":"Bibliotheek TEST -  Volwassenen fictie","sight_sending":false,"status":"draft","reservation_date":null},{"uuid":18102,"name":"PAKB18102","customer_parcel":"Bibliotheek TEST -  Jeugd non-fictie","sight_sending":false,"status":"done","reservation_date":"2023-01-20"},{"uuid":18101,"name":"PAKB18101","customer_parcel":"Bibliotheek TEST -  Jeugd fictie","sight_sending":false,"status":"done","reservation_date":"2023-01-20"},{"uuid":18100,"name":"PAKB18100","customer_parcel":"Bibliotheek TEST -  Volwassenen fictie","sight_sending":false,"status":"done","reservation_date":"2023-01-20"},{"uuid":18099,"name":"PAKB18099","customer_parcel":"Bibliotheek TEST -  Jeugd non-fictie","sight_sending":false,"status":"invoiced","reservation_date":"2023-01-20"},{"uuid":18098,"name":"PAKB18098","customer_parcel":"Bibliotheek TEST -  Jeugd fictie","sight_sending":false,"status":"invoiced","reservation_date":"2023-01-20"},{"uuid":18097,"name":"PAKB18097","customer_parcel":"Bibliotheek TEST -  Volwassenen fictie","sight_sending":false,"status":"invoiced","reservation_date":"2023-01-20"},{"uuid":18096,"name":"PAKB18096","customer_parcel":"Bibliotheek TEST -  Volwassenen non-fictie","sight_sending":true,"status":"draft","reservation_date":null},{"uuid":18093,"name":"PAKB18093","customer_parcel":"Bibliotheek TEST -  Volwassenen fictie","sight_sending":false,"status":"done","reservation_date":"2022-03-01"},{"uuid":18092,"name":"PAKB18092DEF","customer_parcel":"Bibliotheek TEST -  Volwassenen non-fictie","sight_sending":true,"status":"returned","reservation_date":"2022-03-01"}];
      //
      //   const groupBy = function (data, key) {
      //     return data.reduce(function (rv, x) {
      //       (rv[x[key]] = rv[x[key]] || []).push(x);
      //       return rv;
      //     }, {});
      //   };
      //
      //   // Only show done and invoiced
      //   results = groupBy(results, 'status');
      //   results = results['done'].concat(results['invoiced'])
      //
      //   // Group by customer parcel
      //   results = groupBy(results, 'customer_parcel');
      //
      //   // For each parcel -> sort by reservation date
      //   Object.keys(results).forEach((parcel) => {
      //     console.debug(parcel)
      //     results[parcel].sort((a, b) => new Date(b.reservation_date) - new Date(a.reservation_date));
      //   });
      //
      //   data.value = results;
      //   store.commit('clearBusy');
      // }

      getAPI('/api/packing_slips')
          .then((response) => {
            let results = response.data;

            // Sort function
            const groupBy = function (data, key) {
              return data.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };

            // Only show done and invoiced
            results = groupBy(results, 'status');
            results = results['done'].concat(results['invoiced'])

            // Group by customer parcel
            results = groupBy(results, 'customer_parcel');

            // For each parcel -> sort by reservation date
            Object.keys(results).forEach((parcel) => {
              console.debug(parcel)
              results[parcel].sort((a, b) => new Date(b.reservation_date) - new Date(a.reservation_date));
            });

            data.value = results;
            store.commit('clearBusy');
          })
          .catch((error) => {
            // TODO Error handling
            console.log(error);
            store.commit('clearBusy');
          });
    });

    return {
      data,
    }
  }
};

</script>
<style lang="scss">
#packing-slips {
  min-height: calc(100vh - 5rem);
  background: $darker-gray;

  .results-main {
    padding: 2em;
  }
}
</style>
