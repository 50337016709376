<template>
  <div class="columns">
    <div class="column is-8">
      <div class="tile is-ancestor is-vertical" id="main">
        <div class="tile is-parent is-block">
          <FeaturedBooksBlock v-if="!isBusy"/>
          <div class="tile is-child" v-else >
            <LoadingBlock/>
          </div>
        </div>
        <div class="tile">
          <div class="tile is-7 is-parent">
            <SearchBooksBlock />
          </div>
          <div class="tile is-parent">
            <SectionBlock
                title="Bekijk de openstaande bestellingen"
                description=""
                route="/boeken/volgende-levering"
                cta="Nog te leveren boeken"
            />
          </div>
        </div>
        <div class="tile">
          <div class="tile is-parent">
            <SectionBlock
                title="Volg je budget op"
                description=""
                route="budget-controle"
                cta="Budgetcontrole"
            />
          </div>
          <div class="tile is-parent">
            <SectionBlock
              title="Bekijk alle facturen"
              description=""
              route="invoices"
              cta="Facturen"
            />
          </div>
          <div class="tile is-5 is-parent">
            <SearchInvoicesBlock />
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="tile is-ancestor is-vertical" id="side">
        <div class="tile is-parent">
          <SectionBlock
              title="Welke boeken zitten in je volgende levering"
              description=""
              route="/boeken/te-leveren"
              cta="Boeken in volgende levering"
          />
        </div>
        <div class="tile is-parent">
          <SectionBlock
            title="Bekijk alle bestellingen (per order)"
            description=""
            route="/verkooporders"
            cta="Verkooporders"
          />
        </div>
        <div class="tile is-parent is-vertical">
          <PackingOrderBlock />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {getAPI} from "@/utils/useApi";
import FeaturedBooksBlock from "@/app/Components/FeaturedBooksBlock";
import LoadingBlock from "@/app/Components/LoadingBlock";
import SearchBooksBlock from "@/app/Components/SearchBooksBlock";
import SectionBlock from "@/app/Components/SectionBlock";
import SearchInvoicesBlock from "@/app/Components/SearchInvoicesBlock";
import PackingOrderBlock from "@/app/Components/PackingOrderBlock";

export default {
  name: 'Homepage',
  components: {
    FeaturedBooksBlock,
    LoadingBlock,
    SearchBooksBlock,
    SectionBlock,
    SearchInvoicesBlock,
    PackingOrderBlock
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
  },
  setup() {
    const store = useStore();
    const spotlight = ref([]);
    let featuredBooks = [];

    // Fetch data
    onMounted(async () => {
      if (store.getters.getFeaturedBooks.length === 0) {
        getAPI('/api/featured')
            .then((response) => {
              store.dispatch('fetchFeaturedBooks', response.data);
            }).catch((error) => {
          // TODO Error handling
          console.log(error);
        });
      }
    });

    return {
      spotlight,
      featuredBooks
    }
  }
}
</script>

<style lang="scss" scoped>
#main {
  padding: 1rem 0rem 2rem 1rem;
}
#side {
  padding: .75rem 1rem .75rem 0.5rem;
}
#main-mobile {
  margin-top: 1rem;
}
</style>
